import React from "react";

const ConnectionsTable = ({ userData, handleDisconnect, handleReconnect }) => (
  <div>
    <h2 className="text-xl font-semibold mb-4">Connections</h2>
    <div className="overflow-hidden rounded-lg border border-gray-200">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 tracking-wider">
              Connection
            </th>
            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 tracking-wider">
              Date Connected
            </th>
            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <div className="flex items-center">
                <img src="/logo-google.svg" alt="Google" className="h-5 w-5 mr-2" />
                Google
              </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              {userData.google_setup
                ? new Date(userData.google_connected_at).toLocaleDateString()
                : "-"}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              {userData.google_setup ? (
                <button
                  onClick={() => handleDisconnect("google")}
                  className="text-red-500 hover:text-red-700"
                >
                  Disconnect
                </button>
              ) : (
                <button
                  onClick={() => handleReconnect("google")}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Connect Google
                </button>
              )}
            </td>
          </tr>
          <tr>
            <td className="px-6 py-4 whitespace-no-wrap">
              <div className="flex items-center">
                <img src="/logo-slack.svg" alt="Slack" className="h-5 w-5 mr-2" />
                Slack
              </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
              {userData.slack_setup
                ? new Date(userData.slack_connected_at).toLocaleDateString()
                : "-"}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
              {userData.slack_setup ? (
                <button
                  onClick={() => handleDisconnect("slack")}
                  className="text-red-500 hover:text-red-700"
                >
                  Disconnect
                </button>
              ) : (
                <button
                  onClick={() => handleReconnect("slack")}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Connect Slack
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default ConnectionsTable;
