import React, { createContext, useContext, useState } from "react";

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerContent, setHeaderContent] = useState("");
  const [ctaButton, setCTAButton] = useState({ label: "", action: null });

  return (
    <HeaderContext.Provider value={{ headerContent, setHeaderContent, ctaButton, setCTAButton }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);
