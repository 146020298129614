import React from "react";
import SettingsHeader from "./components/SettingsHeader";
import WeekendsToggle from "./components/WeekendsToggle";
import ConnectionsTable from "./components/ConnectionsTable";
import useSettings from "./hooks/useSettings";

const Settings = () => {
  const {
    includeWeekends,
    userData,
    error,
    handleToggleChange,
    handleDisconnect,
    handleReconnect,
  } = useSettings();

  if (error) return <div className="text-red-500 mb-4">Error: {error.message}</div>;

  return (
    <div>
      <SettingsHeader />
      <WeekendsToggle
        includeWeekends={includeWeekends}
        handleToggleChange={handleToggleChange}
      />
      <ConnectionsTable
        userData={userData}
        handleDisconnect={handleDisconnect}
        handleReconnect={handleReconnect}
      />
    </div>
  );
};

export default Settings;
