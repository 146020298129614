import React from "react";
import ToggleSwitch from "../../../components/toggleSwitch";

const WeekendsToggle = ({ includeWeekends, handleToggleChange }) => (
  <div className="mb-6">
    <h2 className="text-xl font-semibold mb-4">Weekends</h2>
    <ToggleSwitch
      checked={includeWeekends}
      onCheckedChange={handleToggleChange}
      label="Include Weekends in Schedule:"
    />
  </div>
);

export default WeekendsToggle;
