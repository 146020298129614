import { useState } from "react";
import { useUser } from "@clerk/clerk-react";

const useSetStatus = () => {
  const { user } = useUser();
  const [error, setError] = useState(null);

  const handleSetStatus = async (newLocation) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/location/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clerk_user_id: user.id, location: newLocation }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Location updated:", result);
      return result;
    } catch (error) {
      console.error("Error updating location:", error);
      setError(error);
      throw error;
    }
  };

  return { handleSetStatus, error };
};

export default useSetStatus;
