import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./modules/homepage";
import Dashboard from "./modules/dashboard";
import Settings from "./modules/settings";
import Setup from "./modules/onboarding";
import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { HeaderProvider } from "./components/header/HeaderContext"; 
import Layout from "./components/layout";

const ProtectedRoute = ({ element }) => {
  return (
    <>
      <SignedIn>
        <HeaderProvider>
          <Layout>
            {element}
          </Layout>
        </HeaderProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/setup" element={<ProtectedRoute element={<Setup />} />} />
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
