const UpcomingSchedule = ({ locations }) => (
  <div className="overflow-hidden p-4 rounded-md border border-gray-200">
    <h3 className="text-lg font-semibold mb-2">Upcoming Schedule</h3>
    <ul>
      {locations.slice(1, 8).map((location, index) => (
        <li key={`${location.working_location}-${location.start_date}-${index}`} className="mb-2 flex justify-between items-center">
          <div>
            {location.working_location} - {new Date(location.start_date).toLocaleDateString()} to {new Date(location.end_date).toLocaleDateString()}
          </div>
          <a href={location.link} target="_blank" rel="noopener noreferrer">
            <button className="bg-blue-500 text-white py-1 px-2 rounded">
              View Event
            </button>
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default UpcomingSchedule;
