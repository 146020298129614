// Setup.js
import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { slackAuthUrl, googleAuthUrl } from "../../auth";
import { useHeader } from "../../components/header/HeaderContext"; 

const Setup = () => {
  const { user } = useUser();
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const { setHeaderContent, setCTAButton } = useHeader();

  setHeaderContent("Setup");
  setCTAButton(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user || !user.id) {
        console.error("User is not available");
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/user?clerk_user_id=${user.id}`
        );
        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.message || "Failed to fetch user data");
        }

        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user, setHeaderContent, setCTAButton]);

  const handleGoogleSetup = () => {
    const clerkUserId = user.id;
    const googleOAuthUrl = `${googleAuthUrl}?clerk_user_id=${clerkUserId}`;
    window.location.href = googleOAuthUrl;
  };

  const handleSlackSetup = () => {
    const userId = user.id;
    window.location.href = `${slackAuthUrl}?user_id=${userId}`;
  };

  const handleContinue = () => {
    navigate("/dashboard");
  };

  return (

      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-4xl font-bold mb-4">Setup your accounts</h1>
        <div className="mb-4">
          {!userData.google_setup && (
            <button
              onClick={handleGoogleSetup}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4"
            >
              Connect Google Account
            </button>
          )}
          {userData.google_setup && <p>Google Account connected</p>}
        </div>
        <div className="mb-4">
          {!userData.slack_setup && (
            <button
              onClick={handleSlackSetup}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4"
            >
              Connect Slack Account
            </button>
          )}
          {userData.slack_setup && <p>Slack Account connected</p>}
        </div>
        {userData.google_setup && userData.slack_setup && (
          <button
            onClick={handleContinue}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Continue to Dashboard
          </button>
        )}
      </div>
  );
};

export default Setup;
