import React, { useEffect, useRef, useState } from 'react';
import styles from './CurrentStatus.module.css';

const slackEmojiMap = {
  ":office:": "🏢",
  ":house_with_garden:": "🏡",
};

const getComputedColor = (element) => {
  const style = getComputedStyle(element);
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  context.fillStyle = style.backgroundColor;
  context.fillRect(0, 0, 1, 1);

  const [r, g, b] = context.getImageData(0, 0, 1, 1).data;
  return { r, g, b };
};

const calculateBrightness = (r, g, b) => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

const replaceSlackEmojis = (text) => {
  return text.replace(/:[a-z_]+:/g, (match) => slackEmojiMap[match] || match);
};

const CurrentStatus = ({ locations, slackStatus }) => {
  const [textColor, setTextColor] = useState(styles.textLight);
  const backgroundRef = useRef();

  useEffect(() => {
    const { r, g, b } = getComputedColor(backgroundRef.current);
    const brightness = calculateBrightness(r, g, b);
    setTextColor(brightness > 128 ? styles.textDark : styles.textLight);
  }, []);

  return (
    <div
      className={`${styles.meshGradientBackground} ${textColor} p-4 rounded-md flex flex-col gap-2`}
      ref={backgroundRef}
    >
      <h3 className="text-lg font-semibold mb-2">Current Status</h3>
      <div className="flex justify-between items-center">
        <span>{locations.length > 0 ? locations[0].working_location : "No location set"}</span>
        {slackStatus && (
          <span>
            {replaceSlackEmojis(slackStatus.status_emoji)} {slackStatus.status_text}
          </span>
        )}
      </div>
    </div>
  );
};

export default CurrentStatus;
