import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styles from './StatusUpdateModal.module.css';

const StatusUpdateModal = ({ isOpen, onOpenChange, onSave }) => {
  const [location, setLocation] = useState('');

  const handleSave = () => {
    onSave(location);
    onOpenChange(false);
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content className={styles.content}>
          <Dialog.Title className={styles.title}>Update Your Working Location</Dialog.Title>
          <Dialog.Description className={styles.description}>
            Choose your current working location:
          </Dialog.Description>
          <div className="mb-4">
            <button
              onClick={() => setLocation('Home')}
              className={`w-full px-3 py-2 mb-2 text-gray-700 border rounded-lg focus:outline-none ${
                location === 'Home' ? 'bg-gray-200' : ''
              }`}
            >
              🏡 Home
            </button>
            <button
              onClick={() => setLocation('Office')}
              className={`w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none ${
                location === 'Office' ? 'bg-gray-200' : ''
              }`}
            >
              🏢 Office
            </button>
          </div>
          <div className="flex justify-end">
            <Dialog.Close asChild>
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
            </Dialog.Close>
            <button
              type="button"
              onClick={handleSave}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default StatusUpdateModal;
