import { useState, useEffect, useCallback } from "react";
import { useUser } from "@clerk/clerk-react";

const useFetchStatus = () => {
  const { user } = useUser();
  const [slackStatus, setSlackStatus] = useState(null);
  const [error, setError] = useState(null);

  const fetchStatus = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/slack/status?clerk_user_id=${user.id}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching status: ${response.statusText}`);
      }
      const statusData = await response.json();
      setSlackStatus(statusData);
    } catch (error) {
      console.error("Error fetching status:", error);
      setError(error);
    }
  }, [user.id]);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  return { slackStatus, fetchStatus, error };
};

export default useFetchStatus;
