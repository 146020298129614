import React, { useEffect } from "react";
import { useHeader } from "../../../components/header/HeaderContext";

const SettingsHeader = () => {
  const { setHeaderContent, setCTAButton } = useHeader();

  useEffect(() => {
    setHeaderContent("Settings");
    setCTAButton(null);
  }, [setHeaderContent, setCTAButton]);

  return null;
};

export default SettingsHeader;
