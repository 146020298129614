import React from "react";
import styles from "./Logo.module.css"; // Ensure correct path to your CSS module

const Logo = () => {
  return (
    <div className="flex items-center justify-between">
      <div className={`${styles.title} ${styles["no-select"]}`}>Revolves</div>
    </div>
  );
};

export default Logo;
