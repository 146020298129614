import React from "react";
import * as Switch from "@radix-ui/react-switch";
import styles from "./ToggleSwitch.module.css";

const ToggleSwitch = ({ checked, onCheckedChange, label }) => {
  return (
    <label className="flex items-center space-x-3">
      <span>{label}</span>
      <Switch.Root
        className={styles.switchRoot}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        <Switch.Thumb className={styles.switchThumb} />
      </Switch.Root>
    </label>
  );
};

export default ToggleSwitch;
