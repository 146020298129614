import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

const useDashboard = () => {
  const { user } = useUser();
  const [userData, setUserData] = useState({});
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/data?clerk_user_id=${user.id}`
        );

        if (!response.ok) {
          throw new Error("Error fetching dashboard data");
        }

        const dashboardData = await response.json();

        if (!dashboardData.user) {
          navigate("/login");
          return;
        }

        if (!dashboardData.user.google_setup || !dashboardData.user.slack_setup) {
          navigate("/setup");
          return;
        }

        setUserData(dashboardData.user);
        setLocations(dashboardData.locations);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError(error);
      }
    };

    fetchData();
  }, [navigate, user.id]);

  return {
    userData,
    locations,
    setLocations, // Ensure setLocations is returned from the hook
    error,
  };
};

export default useDashboard;
