import { useState, useEffect, useCallback } from "react";
import { useUser } from "@clerk/clerk-react";
import { slackAuthUrl, googleAuthUrl } from "../../../auth";

const useSettings = () => {
  const { user } = useUser();
  const [includeWeekends, setIncludeWeekends] = useState(true);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/user/settings?clerk_user_id=${user.id}`
        );
        if (!settingsResponse.ok) {
          throw new Error("Error fetching settings");
        }
        const settingsData = await settingsResponse.json();
        setIncludeWeekends(settingsData.include_weekends);

        const userResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/user?clerk_user_id=${user.id}`
        );
        if (!userResponse.ok) {
          throw new Error("Error fetching user data");
        }
        const userData = await userResponse.json();
        setUserData(userData);
      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error);
      }
    };

    fetchSettings();
  }, [user.id]);

  const handleToggleChange = useCallback(async () => {
    try {
      const newIncludeWeekends = !includeWeekends;
      setIncludeWeekends(newIncludeWeekends);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user/settings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clerk_user_id: user.id,
            include_weekends: newIncludeWeekends,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      setError(error);
    }
  }, [includeWeekends, user.id]);

  const handleDisconnect = useCallback(
    async (type) => {
      const url =
        type === "google"
          ? `${process.env.REACT_APP_BACKEND_URL}/auth/google/disconnect`
          : `${process.env.REACT_APP_BACKEND_URL}/auth/slack/disconnect`;

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ clerk_user_id: user.id }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setUserData((prevData) => ({
          ...prevData,
          [`${type}_setup`]: false,
          [`${type}_connected_at`]: null,
        }));
      } catch (error) {
        console.error(`Error disconnecting ${type}:`, error);
        setError(error);
      }
    },
    [user.id]
  );

  const handleReconnect = useCallback(
    (type) => {
      const clerkUserId = user.id;
      const url =
        type === "google"
          ? `${googleAuthUrl}?clerk_user_id=${clerkUserId}`
          : `${slackAuthUrl}?user_id=${clerkUserId}`;
      window.location.href = url;
    },
    [user.id]
  );

  return {
    includeWeekends,
    userData,
    error,
    handleToggleChange,
    handleDisconnect,
    handleReconnect,
  };
};

export default useSettings;
