import React, { useEffect } from "react";
import { SignInButton, SignUpButton, useSession } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const { session } = useSession();
  const isSignedIn = !!session;
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      const handleSignIn = async () => {
        const sessionId = session.id;

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth/clerk/callback?session_id=${sessionId}`
          );
          const data = await response.json();
          console.log(data);

          if (!data.google_setup || !data.slack_setup) {
            navigate("/setup");
          }
        } catch (error) {
          console.error("Error during Clerk callback:", error);
        }
      };

      handleSignIn();
    }
  }, [isSignedIn, session, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Revolves.app</h1>
      <p className="text-lg text-gray-700 mb-8 text-center">
        Easily connect your Google Working Location to your Slack status so your
        colleagues easily know where you are!
      </p>
      {!isSignedIn && (
        <>
          <SignInButton mode="modal">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4">
              Sign In
            </button>
          </SignInButton>
          <SignUpButton mode="modal">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Sign Up
            </button>
          </SignUpButton>
        </>
      )}
    </div>
  );
};

export default HomePage;
