import { useEffect } from "react";
import { useHeader } from "../../../components/header/HeaderContext";

const DashboardHeader = ({ setIsModalOpen }) => {
  const { setHeaderContent, setCTAButton } = useHeader();

  useEffect(() => {
    setHeaderContent("Your Status");
    setCTAButton({
      label: "Update Location",
      action: () => setIsModalOpen(true),
    });
  }, [setHeaderContent, setCTAButton, setIsModalOpen]);

  return null;
};

export default DashboardHeader;
