import React, { useState } from "react";
import DashboardHeader from "./components/DashboardHeader";
import CurrentStatus from "./components/CurrentStatus";
import UpcomingSchedule from "./components/UpcomingSchedule";
import { useFetchStatus, useSetStatus, StatusUpdateModal } from "../status";
import useDashboard from "./hooks/useDashboard";

const Dashboard = () => {
  const { userData, locations, setLocations, error: dashboardError } = useDashboard();
  const { slackStatus, fetchStatus, error: fetchStatusError } = useFetchStatus();
  const { handleSetStatus, error: setStatusError } = useSetStatus();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSaveLocation = async (newLocation) => {
    try {
      await handleSetStatus(newLocation);
      setLocations((prevLocations) => [
        { working_location: newLocation, start_date: new Date(), end_date: new Date(), html_link: "#" },
        ...prevLocations,
      ]);
      fetchStatus(); // Refetch the current status after updating the location
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  const error = dashboardError || fetchStatusError || setStatusError;

  return (
    <div>
      {error && <div className="text-red-500 mb-4">Error: {error.message}</div>}
      <div className="flex flex-col gap-5">
        <CurrentStatus locations={locations} slackStatus={slackStatus} />
        <UpcomingSchedule locations={locations} />
      </div>
      <DashboardHeader setIsModalOpen={setIsModalOpen} />
      <StatusUpdateModal isOpen={isModalOpen} onOpenChange={setIsModalOpen} onSave={handleSaveLocation} />
    </div>
  );
};

export default Dashboard;
