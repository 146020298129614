import React from "react";
import { NavLink } from "react-router-dom";
import { SignOutButton, UserButton } from "@clerk/clerk-react";
import Logo from "../logo"; // Ensure correct path to your Logo component
import { useHeader } from "../header/HeaderContext"; // Ensure correct path to your HeaderContext
import styles from "./Layout.module.css"; // Ensure correct path to your CSS module

const Layout = ({ children }) => {
  const { headerContent, ctaButton } = useHeader();

  const navbarItems = [
    { label: "Status", path: "/dashboard" },
    { label: "Settings", path: "/settings" },
    { label: "Setup", path: "/setup" }
  ];

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <div>
          <div className="mb-4 flex items-center justify-between">
            <Logo />
            <UserButton />
          </div>
          <nav>
            <ul className="flex flex-col gap-1">
              {navbarItems.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `flex text-left px-4 py-2 rounded ${
                        isActive ? "bg-gray-300" : "hover:bg-white"
                      }`
                    }
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div>
          <SignOutButton>
            <button className="w-full text-left px-4 py-2 bg-red-500 text-white rounded">
              Logout
            </button>
          </SignOutButton>
        </div>
      </aside>
      <div className={styles.contentContainer}>
        {headerContent && (
          <header className={`${styles.header} text-xl font-bold flex justify-between items-center`}>
            <span>{headerContent}</span>
            {ctaButton && ctaButton.label && (
              <button
                onClick={ctaButton.action}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {ctaButton.label}
              </button>
            )}
          </header>
        )}
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
